body{
  background-color: #202D4F;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.progress-wrapper {
  width: 80%;

}

.trix-button-group.trix-button-group--file-tools {
  display:none;
}

h1{
  font-size: 20px !important;
}

#progress-bar {
  width: 100%;
  height:50px;
}

.show {
  opacity: 1;
}

.required-detail{
  color: #8C0000;
  padding-bottom: 10px;
}

.scroll-to {
  scroll-behavior: smooth;
}

.css-13dyovk-MuiButtonBase-root-MuiButton-root{
  background-color: #172038 !important;
  text-transform: none !important;
}

.css-1q8v7ak-MuiSvgIcon-root{
  color: #172038 !important;
}

.css-1oueqvu{
  background-color: #172038 !important;
  text-transform: none !important;
}

body {
  font-family: 'Arimo', sans-serif; /* Use Arimo as the default font */
}

.css-1xjww78{
  background-color: #fff !important;
  color: #172038 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.datetime_style .MuiInputLabel-root {
  transform: translate(0.5rem, -0.7rem) scale(0.75); /* Adjust label position and size */
  background-color: #ffffff;
  padding:5px;

}

.datepicker{
  width:100%;
}

.attachment__progress{
  display:none !important;
}

.attachment__caption-editor{
  display:none !important;
}

.attachment__name{
  display: none !important;
}

.title-bar{
  background-color: #172038 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

